import { template as template_f68ee6cc330540d5a6be4271732c400e } from "@ember/template-compiler";
const FKLabel = template_f68ee6cc330540d5a6be4271732c400e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
